import React from 'react';

import styles from '../GemsPackage/GemsPackage.module.scss';
import background from '../../assets/images/crates/shop_crates_background.png';
import crates1 from '../../assets/images/crates/crates_commander_1.png';
import crates2 from '../../assets/images/crates/crates_commander_2.png';
import crates3 from '../../assets/images/crates/crates_commander_3.png';
import iconInfo from '../../assets/images/icon-info.png';
import IntelHeroic from '../../assets/images/intel/intel_heroic.png';
import IntelRare from '../../assets/images/intel/intel_rare.png';
import IntelUncommon from '../../assets/images/intel/intel_uncommon.png';
import IntelCommon from '../../assets/images/intel/intel_common.png';
import WarToken from '../../assets/images/war_token.png';

import { COMMANDER_RESOURCES_IMAGES } from '../../shared/constants';
import { formatNumber } from '../../shared/helpers';

const crateTypes = ['combat', 'elite', 'legendary'];

const CratesPackage = ({
    crateId,
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    selectOfferById,
    currency,
    openCrateInfoPopUp,
    config,
    WarTokens
}) => {
    const IS_DOUBLE_PROMO_ENABLED = !!+process.env.REACT_APP_PROMO_ON;
    let combatPerksCommon = 25;
    let combatPerksUncommon = 50;
    let elitePerksRare = 100;
    let elitePerksHeroic = 50;
    let legendaryPerksCommon = 100;
    let legendaryPerksUncommon = 100;
    let legendaryPerksRare = 150;
    let legendaryPerksHeroic = 100;

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '2') {

    }

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '3') {

    }

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '4') {

    }

    const crateType = crateTypes[crateId];
    const cratesInfo = {
        combat: {
            img: crates1,
            name: config.cratesNames.combat,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.basicManual,
                        img: COMMANDER_RESOURCES_IMAGES.basicManual,
                        quantity: '2',
                    },
                ],
                perks: [
                    {
                        type: config.common,
                        img: IntelCommon,
                        quantity: combatPerksCommon,
                    },
                    {
                        type: config.uncommon,
                        img: IntelUncommon,
                        quantity: combatPerksUncommon,
                    },
                ],
            },
        },
        elite: {
            img: crates2,
            name: config.cratesNames.elite,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.basicManual,
                        img: COMMANDER_RESOURCES_IMAGES.basicManual,
                        quantity: '2',
                    },
                    {
                        name: config.resourcesNames.tacticsGuide,
                        img: COMMANDER_RESOURCES_IMAGES.tacticsGuide,
                        quantity: '1',
                    },
                ],
                perks: [
                    {
                        type: config.rare,
                        img: IntelRare,
                        quantity: elitePerksRare
                    },
                    {
                        type: config.heroic,
                        img: IntelUncommon,
                        quantity: elitePerksHeroic,
                    },
                ],
            },
        },
        legendary: {
            img: crates3,
            name: config.cratesNames.legendary,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.basicManual,
                        img: COMMANDER_RESOURCES_IMAGES.basicManual,
                        quantity: '2',
                    },
                    {
                        name: config.resourcesNames.tacticsGuide,
                        img: COMMANDER_RESOURCES_IMAGES.tacticsGuide,
                        quantity: '1',
                    },
                ],
                perks: [
                    {
                        type: config.common,
                        img: IntelCommon,
                        quantity: legendaryPerksCommon,
                    },
                    {
                        type: config.uncommon,
                        img: IntelUncommon,
                        quantity: legendaryPerksUncommon,
                    },
                    {
                        type: config.rare,
                        img: IntelRare,
                        quantity: legendaryPerksRare,
                    },
                    {
                        type: config.heroic,
                        img: IntelHeroic,
                        quantity: legendaryPerksHeroic,
                    },
                ],
            },
        },
    };

    const clickOnCard = (isInfo, id, type, img, name) => {
        if (isInfo) {
            const info = {
                name: cratesInfo[crateType].name,
                crateContains: config.crateContains,
                resourcesContent: config.resourcesContent,
                resources: cratesInfo[crateType].content.resources,
                perksContent: config.perksContent,
                perks: cratesInfo[crateType].content.perks,
                price: NewPrice,
                currency: currency,
                crateId: id,
                crateType: type,
                crateImg: img,
                crateName: name,
            };
            openCrateInfoPopUp(info);
        } else {
            selectOfferById(id, type, img, name);
        }
    };

    return (
        <div className={styles.outerWrapper}>
            <div
                className={styles.infoClickArea}
                onClick={() =>
                    clickOnCard(
                        true,
                        OfferId,
                        Type,
                        cratesInfo[crateType].img,
                        cratesInfo[crateType].name
                    )
                }
            ></div>
            <div
                className={`${styles.wrapper} ${styles.crates}`}
                onClick={() =>
                    clickOnCard(
                        false,
                        OfferId,
                        Type,
                        cratesInfo[crateType].img,
                        cratesInfo[crateType].name
                    )
                }
            >
                <img src={background} alt={''} className={styles.background} />
                <div className={`${styles.name} ${styles.crates}`}>
                    {cratesInfo[crateType].name}
                </div>
                <img src={iconInfo} className={styles.info} alt={'img'} />
                <img
                    src={cratesInfo[crateType].img}
                    alt={''}
                    className={`${styles.gemsImage} ${styles.crates} ${styles.commander}`}
                />
                <div className={styles.pricesWrapper}>
                    <div className={styles.oldPrice}>
                        {OldPrice.toFixed(2)} {currency}
                        <div className={'stroke'}></div>
                    </div>
                    <div className={styles.newPrice}>
                        {NewPrice.toFixed(2)} {currency}
                    </div>
                </div>
                <div className="warTokensWrapper">
                    <div>+&nbsp;</div>
                    <div>{formatNumber(WarTokens)}</div>
                    <img className="warTokenImg" src={WarToken} alt='' />
                </div>
            </div>
        </div>
    );
};

export default CratesPackage;
