import React from 'react';

import styles from './MilestoneCard.module.scss';
import Background from '../../assets/images/milestone_card_background.jpg';
import WarToken from '../../assets/images/war_token.png';
import Gem from '../../assets/images/category_icons/ci_gems.png';

import ButtonNew from '../buttons/ButtonNew/ButtonNew';
import { formatNumber } from '../../shared/helpers';

export default function MilestoneCard({
    config,
    Id,
    CollectedWarTokens,
    RequiredTokens,
    GemReward,
    Claimed,
    claimReward
}) {
    let progressInPercent = Math.round((CollectedWarTokens / RequiredTokens) * 100);
    if ((progressInPercent === 100) && (CollectedWarTokens < RequiredTokens)) {
        progressInPercent = 99;
    }
    if (progressInPercent > 100) {
        progressInPercent = 100;
    }

    return (
        <div className={styles.wrapper}>
            <img className={styles.background} src={Background} alt='' />
            <div className={styles.name}>{config.milestone}&nbsp;{Id}</div>
            <div className={styles.progressWrapper}>
                <div className={styles.currentProgres}>{CollectedWarTokens < RequiredTokens ? formatNumber(CollectedWarTokens) : formatNumber(RequiredTokens)}&nbsp;/&nbsp;</div>
                <div className={styles.totalProgress}>{formatNumber(RequiredTokens)}&nbsp;</div>
                <img className={styles.tokenImg} src={WarToken} alt='' />
            </div>
            <div className={styles.progressBarWrapper}>
                <div className={styles.progressBar} style={{width: `${progressInPercent}%`}}></div>
                <div className={styles.percent}>{progressInPercent}%</div>
            </div>
            <div className={styles.rewardWrapper}>
                <div className={styles.reward}>{config.reward}</div>
                <div className={styles.rewardAmountWrapper}>
                    <div className={styles.reward}>{formatNumber(GemReward)}</div>
                    <img className={styles.gemImg} src={Gem} alt='' />
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                {Claimed ?
                    <div className={styles.claimed}>{config.claimed}</div>
                :
                    <ButtonNew
                        text={config.claim}
                        onClick={() => claimReward(Id, progressInPercent)}
                        isFancy={true}
                        isLarge={true}
                        isDisabled={(CollectedWarTokens < RequiredTokens) ? true : false}
                    />
                }
            </div>
        </div>
    );
};
