import React from 'react';

import styles from '../GemsPackage/GemsPackage.module.scss';
import background from '../../assets/images/crates/shop_crates_background.png';
import crates1 from '../../assets/images/crates/crates1.png';
import crates2 from '../../assets/images/crates/crates2.png';
import crates3 from '../../assets/images/crates/crates3.png';
import iconInfo from '../../assets/images/icon-info.png';
import perkCommon from '../../assets/images/perk-common.png';
import perkUncommon from '../../assets/images/perk-uncommon.png';
import perkRare from '../../assets/images/perk-rare.png';
import perkHeroic from '../../assets/images/perk-heroic.png';
import WarToken from '../../assets/images/war_token.png';

import { RESOURCES_IMAGES } from '../../shared/constants';
import { formatNumber } from '../../shared/helpers';

const crateTypes = ['captains', 'majors', 'colonels'];

const CratesPackage = ({
    ImageId,
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    selectOfferById,
    currency,
    openCrateInfoPopUp,
    config,
    WarTokens
}) => {
    const IS_DOUBLE_PROMO_ENABLED = !!+process.env.REACT_APP_PROMO_ON;
    let captainsPerksCommon = 1;
    let captainsPerksUncommon = 2;
    let majorsPerksCommon = 1;
    let majorsPerksUncommon = 1;
    let majorsPerksRare = 2;
    let colonelsPerksRare = 2;
    let colonelsPerksHeroic = 2;

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '2') {
        captainsPerksCommon = 2;
        captainsPerksUncommon = 4;
        majorsPerksCommon = 2;
        majorsPerksUncommon = 2;
        majorsPerksRare = 4;
        colonelsPerksRare = 4;
        colonelsPerksHeroic = 4;
    }

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '3') {
        captainsPerksCommon = 3;
        captainsPerksUncommon = 6;
        majorsPerksCommon = 3;
        majorsPerksUncommon = 3;
        majorsPerksRare = 6;
        colonelsPerksRare = 6;
        colonelsPerksHeroic = 6;
    }

    if(process.env.REACT_APP_PROMO_MULTIPLYER == '4') {
        captainsPerksCommon = 4;
        captainsPerksUncommon = 8;
        majorsPerksCommon = 4;
        majorsPerksUncommon = 4;
        majorsPerksRare = 8;
        colonelsPerksRare = 8;
        colonelsPerksHeroic = 8;
    }


    const crateType = crateTypes[ImageId];
    const cratesInfo = {
        captains: {
            img: crates1,
            name: config.cratesNames.captains,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.paper,
                        img: RESOURCES_IMAGES.paper,
                        quantity: '20 000',
                    },
                    {
                        name: config.resourcesNames.coal,
                        img: RESOURCES_IMAGES.coal,
                        quantity: '10 000',
                    },
                    {
                        name: config.resourcesNames.scrap,
                        img: RESOURCES_IMAGES.scrap,
                        quantity: '500',
                    },
                ],
                perks: [
                    {
                        type: config.common,
                        img: perkCommon,
                        quantity: captainsPerksCommon,
                    },
                    {
                        type: config.uncommon,
                        img: perkUncommon,
                        quantity: captainsPerksUncommon,
                    },
                ],
            },
        },
        majors: {
            img: crates2,
            name: config.cratesNames.majors,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.paper,
                        img: RESOURCES_IMAGES.paper,
                        quantity: '37 500',
                    },
                    {
                        name: config.resourcesNames.coal,
                        img: RESOURCES_IMAGES.coal,
                        quantity: '18 750',
                    },
                    {
                        name: config.resourcesNames.metal,
                        img: RESOURCES_IMAGES.metal,
                        quantity: '6 000',
                    },
                    {
                        name: config.resourcesNames.scrap,
                        img: RESOURCES_IMAGES.scrap,
                        quantity: '1 000',
                    },
                ],
                perks: [
                    {
                        type: config.common,
                        img: perkCommon,
                        quantity: majorsPerksCommon,
                    },
                    {
                        type: config.uncommon,
                        img: perkUncommon,
                        quantity: majorsPerksUncommon,
                    },
                    {
                        type: config.rare,
                        img: perkRare,
                        quantity: majorsPerksRare,
                    },
                ],
            },
        },
        colonels: {
            img: crates3,
            name: config.cratesNames.colonels,
            content: {
                resources: [
                    {
                        name: config.resourcesNames.paper,
                        img: RESOURCES_IMAGES.paper,
                        quantity: '50 000',
                    },
                    {
                        name: config.resourcesNames.coal,
                        img: RESOURCES_IMAGES.coal,
                        quantity: '25 000',
                    },
                    {
                        name: config.resourcesNames.metal,
                        img: RESOURCES_IMAGES.metal,
                        quantity: '10 000',
                    },
                    {
                        name: config.resourcesNames.ammo,
                        img: RESOURCES_IMAGES.ammo,
                        quantity: '4 000',
                    },
                    {
                        name: config.resourcesNames.scrap,
                        img: RESOURCES_IMAGES.scrap,
                        quantity: '1 500',
                    },
                ],
                perks: [
                    {
                        type: config.rare,
                        img: perkRare,
                        quantity: colonelsPerksRare,
                    },
                    {
                        type: config.heroic,
                        img: perkHeroic,
                        quantity: colonelsPerksHeroic,
                    },
                ],
            },
        },
    };

    const clickOnCard = (isInfo, id, type, img, name) => {
        if (isInfo) {
            const info = {
                name: cratesInfo[crateType].name,
                crateContains: config.crateContains,
                resourcesContent: config.resourcesContent,
                resources: cratesInfo[crateType].content.resources,
                perksContent: config.perksContent,
                perks: cratesInfo[crateType].content.perks,
                price: NewPrice,
                currency: currency,
                crateId: id,
                crateType: type,
                crateImg: img,
                crateName: name,
            };
            openCrateInfoPopUp(info);
        } else {
            selectOfferById(id, type, img, name);
        }
    };

    return (
        <div className={styles.outerWrapper}>
            <div
                className={styles.infoClickArea}
                onClick={() =>
                    clickOnCard(
                        true,
                        OfferId,
                        Type,
                        cratesInfo[crateType].img,
                        cratesInfo[crateType].name
                    )
                }
            ></div>
            <div
                className={`${styles.wrapper} ${styles.crates}`}
                onClick={() =>
                    clickOnCard(
                        false,
                        OfferId,
                        Type,
                        cratesInfo[crateType].img,
                        cratesInfo[crateType].name
                    )
                }
            >
                <img src={background} alt={''} className={styles.background} />
                <div className={`${styles.name} ${styles.crates}`}>
                    {cratesInfo[crateType].name}
                </div>
                <img src={iconInfo} className={styles.info} alt={'img'} />
                <img
                    src={cratesInfo[crateType].img}
                    alt={''}
                    className={`${styles.gemsImage} ${styles.crates}`}
                />
                <div className={styles.pricesWrapper}>
                    <div className={styles.oldPrice}>
                        {OldPrice.toFixed(2)} {currency}
                        <div className={'stroke'}></div>
                    </div>
                    <div className={styles.newPrice}>
                        {NewPrice.toFixed(2)} {currency}
                    </div>
                </div>
                <div className="warTokensWrapper">
                    <div>+&nbsp;</div>
                    <div>{formatNumber(WarTokens)}</div>
                    <img className="warTokenImg" src={WarToken} alt='' />
                </div>
            </div>
        </div>
    );
};

export default CratesPackage;
