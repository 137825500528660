import React, {useContext} from 'react';
import { NavLink } from "react-router-dom";

import styles from './Nav.module.scss';

import { DEFAULT_MENUS } from "../../shared/constants";
import { MilestonesContext } from '../../App';

const Nav = ({ menus, hasUnclaimedMilestones }) => {
    const navMenus = (menus && menus.length) ? menus : DEFAULT_MENUS;
    const availableMilestones = useContext(MilestonesContext);

    return (
        <div className={styles.wrapper}>
            {navMenus.map((menu, index) => {
                return (
                    <div className={styles.menuItemWrapper} key={index}>
                        {menu.isShop ?
                            <NavLink
                                to={menu.url}
                                className={({ isActive }) =>  isActive ? `${styles.menuItemWrapper} ${styles.active}` : `${styles.menuItemWrapper}`}
                            >
                                <div className={`${styles.menuItem} ${menu.isHighlighted ? styles.highlighted : ''} ${menu.isSpecial ? styles.special : ''} ${menu.name === 'shop' ? styles.shop : ''}`}>
                                    <div className={styles.innerPart}>
                                        {menu.name}
                                    </div>
                                    {hasUnclaimedMilestones && menu.isSpecial ?
                                        <div className={styles.indicator}></div>
                                    :
                                        null
                                    }
                                </div>
                            </NavLink>
                        :
                            <div className={`${styles.menuItem} ${menu.isHighlighted ? styles.highlighted : ''}`} onClick={() => window.location.replace(menu.url)}>
                                <div className={styles.innerPart}>
                                    {menu.name}
                                </div>
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default Nav;