import React, { useState, useEffect } from 'react';
import {CSSTransition} from "react-transition-group";
import {useLocation, useNavigate} from "react-router-dom";

import styles from './FilterMobile.module.scss';

import {OFFER_NAME_TO_CONFIG_TYPE, OFFER_TYPE_TO_CONFIG_ICON, OFFER_TYPE_TO_CONFIG_NAME} from "../../shared/constants";

const FilterMobile = ({ offersCategories, text, categoryNames }) => {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(OFFER_NAME_TO_CONFIG_TYPE.specialOffers);
    const selectedCategories = Object.values(offersCategories).filter(category => category.isVisible);

    const goToCategory = (categoryId) => {
        document.getElementById(categoryId).scrollIntoView({behavior: "smooth"});
        navigate(`#${OFFER_TYPE_TO_CONFIG_NAME[categoryId]}`);
        setIsOpen(false);
    }

    useEffect(() => {
        let activeCategoryName = '';
        if (hash) {
            activeCategoryName = hash;
            activeCategoryName = activeCategoryName.slice(1);
            activeCategoryName = OFFER_NAME_TO_CONFIG_TYPE[activeCategoryName];
            document.getElementById(activeCategoryName).scrollIntoView({behavior: "smooth"});
        } else {
            if (offersCategories.webShopExclusiveOffers) {
                activeCategoryName = OFFER_NAME_TO_CONFIG_TYPE['webShopExclusiveOffers'];
            } else if (offersCategories.eventPassOffers) {
                activeCategoryName = OFFER_NAME_TO_CONFIG_TYPE['eventPassOffers'];
            } else {
                activeCategoryName = OFFER_NAME_TO_CONFIG_TYPE['specialOffers'];
            }
        }
        setActiveCategory(activeCategoryName);
    }, [hash]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    return (
        <>
            <CSSTransition in={isOpen} timeout={300} classNames="fade-in-slide-up" unmountOnExit>
                <div className={`${styles.filtersWrapper} ${isOpen ? 'open' : ''}`}>
                    {Object.values(offersCategories).map((category, index) => {
                        const categoryName = OFFER_TYPE_TO_CONFIG_NAME[category.type];
                        const categoryIcon = OFFER_TYPE_TO_CONFIG_ICON[category.type];
                        return (
                            <div key={index} className={`${styles.categoryWrapper} ${(category.type === activeCategory) ? styles.active : ''}`}
                                 onClick={() => goToCategory(category.type)}
                            >
                                <img className={styles.categoryIcon} src={categoryIcon} alt={''} />
                                <div className={styles.categoryName}>{categoryNames[categoryName]}</div>
                                {category.isNew && <div className={styles.badgeNew}>new</div>}
                            </div>
                        )
                    })}
                </div>
            </CSSTransition>
            {Object.keys(offersCategories).length ?
                <div className={styles.filterBarWrapper} onClick={() => setIsOpen(!isOpen)}>
                    <div className={styles.text}>{text} {`(${selectedCategories.length})`}</div>
                </div>
                :
                null
            }
        </>
    );
};

export default FilterMobile;