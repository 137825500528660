import React, { useState, useContext } from 'react';

import styles from './NavMobile.module.scss';

import {NavLink} from "react-router-dom";
import {DEFAULT_MENUS} from "../../../shared/constants";
import { MilestonesContext } from '../../../App';

const NavMobile = ({ menus, hasUnclaimedMilestones }) => {
    const [isOpened, setIsOpened] = useState(false);
    const navMenus = (menus && menus.length) ? menus : DEFAULT_MENUS;
    const availableMilestones = useContext(MilestonesContext);

    return (
        <>
            <div className={`${styles.container} ${isOpened ? styles.open : ''}`} onClick={() => {setIsOpened(!isOpened)}}>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
                <div className={styles.bar3}></div>
                {hasUnclaimedMilestones ?
                    <div className={styles.indicator}></div>
                :
                    null
                }
            </div>
            <div className={`${styles.menusList} ${isOpened ? styles.opened : ''}`}>
                {navMenus.map((menu, index) => (
                    <div key={index}>
                        { menu.isShop ?
                            <NavLink
                                to={menu.url}
                                className={({ isActive }) =>  isActive ? `${styles.menu} ${styles.shopMenu} ${styles.active}` : `${styles.menu} ${styles.shopMenu}`}
                                onClick={() => setIsOpened(false)}
                            >
                                <span className={styles.nemuName}>
                                    {menu.name}
                                    {hasUnclaimedMilestones && menu.isSpecial ?
                                        <div className={styles.indicator}></div>
                                        :
                                        null
                                    }
                                </span>
                            </NavLink>
                        :
                            <div className={styles.menu} onClick={() => { window.location.replace(menu.url) }}>
                                {menu.name}
                            </div>
                        }
                        <div className={'dividerHorizontal'}></div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default NavMobile;