import React from 'react';

import styles from '../GemsPackage/GemsPackage.module.scss';
import background from '../../assets/images/gems/shop_gems_background.png';
import WarToken from '../../assets/images/war_token.png';

import {RESOURCES_IMAGES} from "../../shared/constants";
import { formatNumber } from '../../shared/helpers';

const ResourcesPackage = ({
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    ResourceType,
    Amount,
    selectOfferById,
    currency,
    resourcesNames,
    WarTokens
}) => {
    const assets = {
        1: { img: RESOURCES_IMAGES.paper, name: resourcesNames.paper },
        2: { img: RESOURCES_IMAGES.coal, name: resourcesNames.coal },
        3: { img: RESOURCES_IMAGES.metal, name: resourcesNames.metal },
        4: { img: RESOURCES_IMAGES.ammo, name: resourcesNames.ammo },
        5: { img: RESOURCES_IMAGES.scrap, name: resourcesNames.scrap }
    };

    return (
        <div className={styles.wrapper} onClick={() => selectOfferById(OfferId, Type, assets[ResourceType].img, assets[ResourceType].name, Amount, ResourceType)}>
            <img src={background} alt={''} className={styles.background} />
            <div className={styles.name}>{assets[ResourceType].name}</div>
            <div className={styles.quantity}>{Amount.toLocaleString()}</div>
            <img src={assets[ResourceType].img} alt={''} className={`${styles.gemsImage} ${styles.resources}`} />
            <div className={styles.pricesWrapper}>
                <div className={styles.oldPrice}>
                    {OldPrice.toFixed(2)} {currency}
                    <div className={'stroke'}></div>
                </div>
                <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
            </div>
            <div className="warTokensWrapper">
                <div>+&nbsp;</div>
                <div>{formatNumber(WarTokens)}</div>
                <img className="warTokenImg" src={WarToken} alt='' />
            </div>
        </div>
    );
};

export default ResourcesPackage;