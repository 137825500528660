import React from 'react';

import styles from '../SpecialOffer/SpecialOffer.module.scss';
import offerPriceBg from '../../assets/images/offer-price-bg.png';

const FreeOffer = ({ ImageURL, OfferId, Type, buttonName, claimFreeOffer }) => {

    return (
        <div className={`${styles.wrapper} ${styles.freeOffer}`}>
            <img src={ImageURL} className={styles.offerImg} />
            <div className={styles.priceWrapper} onClick={() => claimFreeOffer(OfferId, Type)}>
                <img src={offerPriceBg} alt={''} />
                <div className={styles.pricesWrapper}>
                    {buttonName}
                </div>
            </div>
        </div>
    );
};

export default FreeOffer;