import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './Milestones.module.scss';
import WarToken from '../../assets/images/war_token.png';
import Arrow from '../../assets/images/arrow_yellow.png';

import PopupInfo from '../../components/PopupInfo/PopupInfo';
import Loading from '../../components/Loading/Loading';
import {ENV, MOBILE_VIEW_BREAKPOINT, TOASTS_TYPES} from '../../shared/constants';
import { request } from '../../shared/request';
import MilestoneCard from '../../components/MilestoneCard/MilestoneCard';
import { verifyMilestoneHashAndGetId, hasMilestonesToClaimLeft } from '../../shared/helpers';

const MAX_TOASTS_ON_SCREEN = 3;
const TOAST_SCREENTIME = 5000;

export default function Milestones({ config, windowSize, setIsLogged, setHasUnclaimedMilestones }) {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const shownToasts = useRef([]);
    const milestonesWrapper = useRef();
    const [allMilestones, setAllMilestones] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const [hasEmptyMilestones, setHasEmptyMilestones] = useState(false);

    const logOut = () => {
        localStorage.removeItem('playerInfo');
        localStorage.removeItem('selectedOffer');
        localStorage.removeItem('adyen-checkout__session');
        localStorage.removeItem('filters');
        setIsLogged(false);
        setHasUnclaimedMilestones(false);
        navigate('/');
    };

    const getMilestones = async () => {
        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);

        try {
            setIsLoading(true);
            const response = await request.get(`getPlayerPromotions/${player.id}`);

            if (!response.empty && response.CanShop) {
                if (response.Milestones && response.Milestones.length) {
                    setAllMilestones(response.Milestones);
                    setHasEmptyMilestones(false);
                } else {
                    setAllMilestones([]);
                    setHasEmptyMilestones(true);
                }
            } else {
                logOut();
                localStorage.removeItem('playerInfo');
                navigate('/');
            }
        } catch (err) {
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const claimReward = async (milestoneId, progressInPercent) => {
        if (progressInPercent < 100) {
            return;
        }

        const nextMilestone = allMilestones.find((milestone) => milestone.Id === milestoneId + 1);
        if (nextMilestone && !nextMilestone.Claimed && (nextMilestone.CollectedWarTokens >= nextMilestone.RequiredTokens)) {
            navigate(`#milestone${milestoneId + 1}`);
        } else {
            navigate(`#milestone${milestoneId}`);
        }

        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);

        const milestoneIndex = allMilestones.findIndex((milestone) => milestone.Id === milestoneId);

        try {
            setIsLoading(true);
            const response = await request.post(`claimMilestoneReward/${player.id}/${milestoneId}`);

            if (response) {
                if (response.Success) {
                    toast.success(config.milestoneClaimSuccess);
                    const updatedMilestones = [...allMilestones];
                    const updatedMilestone = updatedMilestones.find((milestone) => milestone.Id === milestoneId);
                    updatedMilestone.Claimed = true;
                    setAllMilestones(updatedMilestones);

                    if (!hasMilestonesToClaimLeft(updatedMilestones)) {
                        setHasUnclaimedMilestones(false);
                    }
                } else {
                    toast.error(config.milestoneClaimFail);
                }
            }
        } catch (err) {
            toast.error(config.milestoneClaimFail);
            setError({
                hasError: true,
                message: ENV === 'test' ? err.message : null,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const toastsDeboucer = (type) => {
        if (shownToasts.current.length >= MAX_TOASTS_ON_SCREEN) {
            toast.dismiss(shownToasts.current[0]);
            shownToasts.current.shift();
            let currentToastId = null;

            if (type === TOASTS_TYPES.success) {
                currentToastId = toast.success(config.milestoneClaimSuccess);
            } else {
                currentToastId = toast.error(config.milestoneClaimFail);
            }

            shownToasts.current.push(currentToastId);
            removeToastId(currentToastId);
        } else {
            let currentToastId = null;

            if (type === TOASTS_TYPES.success) {
                currentToastId = toast.success(config.milestoneClaimSuccess);
            } else {
                currentToastId = toast.error(config.milestoneClaimFail);
            }

            shownToasts.current.push(currentToastId);
            removeToastId(currentToastId);
        }
    };

    const removeToastId = (id) => {
        setTimeout(() => {
            shownToasts.current && shownToasts.current.length > 0 && shownToasts.current.splice(id, 1);
        }, TOAST_SCREENTIME);
    };

    const  transformScroll = (event) => {
        if (window.innerWidth > MOBILE_VIEW_BREAKPOINT) {
            event.preventDefault();

            if (!event.deltaY) {
                return;
            }

            event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
        }
    }

    const goToMilestone = (milestoneId) => {
        document.getElementById(milestoneId).scrollIntoView({behavior: "smooth"});
        navigate(`#${milestoneId}`);
    }

    useEffect(() => {
        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);

        if (!player || !player.id) {
            logOut();
        }
    }, []);

    useEffect(() => {
        getMilestones();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            milestonesWrapper.current.addEventListener('wheel', transformScroll);
        }
    }, [isLoading]);

    useEffect(() => {
        let selectedMilestone = '';

        if (hash && !isLoading && (allMilestones && allMilestones.length)) {
            selectedMilestone = verifyMilestoneHashAndGetId(hash);
            if (selectedMilestone) {
                document.getElementById(selectedMilestone).scrollIntoView({ behavior: "smooth" });
            }
        }

        if (!hash && !isLoading && (allMilestones && allMilestones.length)) {
            const milestoneToScroll = allMilestones.find((milestone) => {
                return !milestone.Claimed && (milestone.CollectedWarTokens >= milestone.RequiredTokens)
            });

            if (milestoneToScroll && milestoneToScroll.Id) {
                document.getElementById(`milestone${milestoneToScroll.Id}`).scrollIntoView({behavior: "smooth"});
            } else {
                const lastClaimedMilestone = allMilestones.findLastIndex((milestone) => milestone.Claimed);
                if ((lastClaimedMilestone !== -1) && (lastClaimedMilestone <= allMilestones.length - 1)) {
                    document.getElementById(`milestone${lastClaimedMilestone + 1}`).scrollIntoView({behavior: "smooth"});
                }
            }
        }
    }, [hash, isLoading]);

    return isLoading ? (
        <Loading />
    ) : (
        <div className={styles.wrapper}>
            <div className={styles.milestonesTitle}>
                {config.milestones}
            </div>
            <div className={styles.milestonesDescription}>
                {config.getWarTokens}
                <span className={styles.imgPart}>&nbsp;(<img className={styles.warTokenImg} src={WarToken} alt='' />)&nbsp;</span>
                {config.warTokensDesc}
            </div>
            <div className={styles.milestonesWrapper} ref={milestonesWrapper}>
                {allMilestones && allMilestones.length > 0 && allMilestones.map((milestone, index) => (
                    <div key={index} id={`milestone${index + 1}`} className={`${styles.milestoneCardWrapper} ${!windowSize.isMobileView ? styles.horizontal : ''}`}>
                        <MilestoneCard config={config} {...milestone} claimReward={claimReward} />
                        {index !== (allMilestones.length - 1) ?
                            <img
                                className={styles.milestoneArrow}
                                src={Arrow}
                                alt={''}
                                onClick={() => goToMilestone(`milestone${index + 2}`)}
                            />
                        :
                            null
                        }
                    </div>
                ))}
            </div>
            {hasEmptyMilestones ?
                <div className={styles.noMilestonesWrapper}>
                    {config.noMilestones}
                </div>
            :
                null
            }
            {error.hasError ?
                <PopupInfo text={error.message} setError={setError} />
                :   
                null
            }
        </div>    
    );
};
