import React from 'react';

import styles from '../GemsPackage/GemsPackage.module.scss';
import background from '../../assets/images/gems/shop_gems_background.png';
import WarToken from '../../assets/images/war_token.png';

import {COMMANDER_OFFERS_IMAGES, COMMANDER_OFFERS_NAMES} from "../../shared/constants";
import { formatNumber } from '../../shared/helpers';
import CratesPackageCommander from "../CratesPackage/CratesPackageCommander";

const CommandersPackages = ({
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    Amount,
    selectOfferById,
    currency,
    WarTokens,
    openCrateInfoPopUp,
    config,
    index
}) => {
    const assets = {
        23: { img: COMMANDER_OFFERS_IMAGES.experience[OfferId], name: COMMANDER_OFFERS_NAMES.experience[OfferId] },
    };

    const cratePackage = {
        OldPrice,
        NewPrice,
        OfferId,
        Type,
        selectOfferById,
        currency,
        WarTokens,
    }

    return (
        <>
            {Type === 13 ?
                <CratesPackageCommander
                    {...cratePackage}
                    selectOfferById={selectOfferById}
                    currency={currency}
                    openCrateInfoPopUp={openCrateInfoPopUp}
                    config={config}
                    crateId={index}
                />
                :
                <div className={styles.wrapper} onClick={() => selectOfferById(OfferId, Type, assets[Type].img, assets[Type].name, Amount, Type)}>
                    <img src={background} alt={''} className={styles.background} />
                    <div className={styles.name}>{assets[Type].name}</div>
                    <div className={styles.quantity}>{Amount.toLocaleString()}&nbsp;XP</div>
                    <img src={assets[Type].img} alt={''} className={`${styles.gemsImage} ${styles.resources}`} />
                    <div className={styles.pricesWrapper}>
                        <div className={styles.oldPrice}>
                            {OldPrice.toFixed(2)} {currency}
                            <div className={'stroke'}></div>
                        </div>
                        <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
                    </div>
                    <div className="warTokensWrapper">
                        <div>+&nbsp;</div>
                        <div>{formatNumber(WarTokens)}</div>
                        <img className="warTokenImg" src={WarToken} alt='' />
                    </div>
                </div>
            }
        </>
    );
};

export default CommandersPackages;